import * as amplitude from '@amplitude/analytics-browser';
import { IS_DEVELOPMENT } from '../config';
import type { AmplitudeOptions, AmplitudeEvent } from '../types';

/**
 * @name initClientAmplitude
 * @external https://www.npmjs.com/package/@amplitude/analytics-browser
 * @external https://www.docs.developers.amplitude.com/data/sdks/typescript-browser
 * @description Update the "logLevel" below to "Warn" to minimize noise
 * or "Debug" for tons of info
 */
export const initClientAmplitude = async (amplitudeKey: string) => {
  // eslint-disable-next-line no-console
  if (IS_DEVELOPMENT) console.log(`📊 Amplitude (browser)`);

  const { Warn, Error } = amplitude.Types.LogLevel;
  const logLevel = IS_DEVELOPMENT ? Warn : Error;

  amplitude.init(amplitudeKey, undefined, { logLevel });
};

/**
 * @name getTrackClientEvent
 * @description Returns an app specific function to track Client Side events in Amplitude
 * @link https://www.docs.developers.amplitude.com/data/sdks/browser-2/#tracking-an-event
 */
export const getTrackClientEvent =
  <T extends AmplitudeEvent>(appVersion: string, appName: string) =>
  (
    name: T['name'],
    eventProperties: Omit<T, 'name'>,
    options: AmplitudeOptions,
  ) => {
    const eventOptions = {
      app_version: appVersion,
      device_id: appName,
      ...options,
    };

    return amplitude.track(name, eventProperties, eventOptions);
  };
