import { Timestamp } from '@shiftsmartinc/shiftsmart-types';

/**
 * @name formatDateTime
 * @description `{ day: 'numeric', hour: 'numeric', minute: 'numeric', month: 'long', year: 'numeric' }`
 * @returns a date converted to the given timezone and formatted as `Month Day, Year`
 */
export const formatDateTime = <
  D extends Date | string | Timestamp | undefined | null,
>(
  date: D,
  timeZone: string,
  options?: Intl.DateTimeFormatOptions,
) => {
  return format(date, timeZone, {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'long',
    year: 'numeric',
    ...options,
  });
};

/**
 * @name formatDate
 * @description `{ day: 'numeric', month: 'long', year: 'numeric' }`
 * @returns a date converted to the given timezone and formatted as `Month Day, Year`
 */
export const formatDate = <
  D extends Date | string | Timestamp | undefined | null,
>(
  date: D,
  timeZone: string,
  options?: Intl.DateTimeFormatOptions,
) => {
  return format(date, timeZone, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    ...options,
  });
};

/**
 * @name formatTime
 * @description `{ hour: 'numeric', minute: 'numeric' }`
 * @returns a date converted to ET timezone and formatted as `HH:MM AM/PM`
 */
export const formatTime = <
  D extends Date | string | Timestamp | undefined | null,
>(
  date: D,
  timeZone: string,
  options?: Intl.DateTimeFormatOptions,
) => {
  return format(date, timeZone, {
    hour: 'numeric',
    minute: 'numeric',
    ...options,
  });
};

/**
 * @name format
 * @param date a date in UTC timezone
 * @param timeZone the timezone to convert to
 * @param options to override the default options
 * @returns a date converted to the given timezone and formatted
 */
export const format = <D extends Date | string | Timestamp | undefined | null>(
  dateProp: D,
  timeZone: string,
  options?: Intl.DateTimeFormatOptions,
) => {
  if (typeof dateProp === 'undefined' || dateProp === null) return dateProp;

  const date = dateProp instanceof Date ? dateProp : new Date(dateProp);

  const mergedOptions: Intl.DateTimeFormatOptions = {
    ...options,
    timeZone,
  };

  const formatted = date.toLocaleString('en-US', mergedOptions);

  // Replace unicode non-breaking space with regular space
  const cleaned = formatted.replace(/\u202f/g, ' ');

  return cleaned;
};

/**
 * @name getTimezoneOffset
 * @param timeZone the timezone to get the UTC offset from
 * @returns number of hours offset from UTC
 */
export function getTimezoneOffset(
  timeZone: NonNullable<Intl.DateTimeFormatOptions['timeZone']>,
) {
  const now = new Date();
  const nowUtc = now.toLocaleString('en-US', { timeZone: 'UTC' });
  const nowLocal = now.toLocaleString('en-US', { timeZone: timeZone });
  return new Date(nowUtc).getTime() - new Date(nowLocal).getTime();
}

/**
 * @name localToUtc
 * @param date the local date to convert to UTC
 * @param timeZone the timezone of the local date
 * @returns a date converted to UTC
 */
export function localToUtc(
  date: Date,
  timeZone: NonNullable<Intl.DateTimeFormatOptions['timeZone']>,
) {
  const timezoneOffset = getTimezoneOffset(timeZone);
  return new Date(date.getTime() + timezoneOffset);
}

/**
 * @name utcToLocal
 * @param date the UTC date to convert to local
 * @param timeZone the timezone to convert to local
 * @returns a date converted to local
 */
export function utcToLocal(
  date: Date,
  timeZone: NonNullable<Intl.DateTimeFormatOptions['timeZone']>,
) {
  const timezoneOffset = getTimezoneOffset(timeZone);
  return new Date(date.getTime() - timezoneOffset);
}
