export type FetchErrorCode = number | string;

/**
 * @description This custom error class supports a custom "name", "code", and "data"
 */
export class FetchError extends Error {
  public code: FetchErrorCode;
  public data: any;

  constructor(
    message: string,
    code: FetchErrorCode,
    name?: string,
    data?: any,
  ) {
    super(message);

    this.code = code;
    this.message = message;
    this.name = name ?? 'FetchError';
    this.data = data;
  }
}

export interface ErrorAsString {
  message: string;
  stack: string;
}

/**
 * @name parseErrorAsString
 * @description Utility function to parse an error as a structured object
 * which we stringify for rendering and easy logging & copy/paste.
 */
export const parseErrorAsString = (error: unknown) => {
  const isError = error instanceof Error;
  const isObject = typeof error === 'object';

  const data: ErrorAsString = {
    message: isError ? error.message : String(error),
    stack: isError && error.stack ? error.stack : 'Stack is unavailable',
  };

  if (isObject && !isError) {
    data.message = 'Error Object';
    data.stack = error as unknown as string; // We stringify it below
  }

  return JSON.stringify(data, null, 2);
};
